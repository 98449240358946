import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik"
import * as React from "react"
import * as Yup from "yup"

interface FormValues {
  name: string
  mail: string
  phone: string
  subject: string
  message: string
  address: string
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid")
const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Votre nom est requis"),
  address: Yup.string().required("Votre adresse est requise"),
  mail: Yup.string()
    .email("l'email n'est pas valide")
    .required("Votre email est requis"),
  phone: Yup.string()
    .matches(phoneRegExp, "Le numéro ne semble pas valide")
    .required("Votre numéro est requis"),
  message: Yup.string()
    .min(10, "Votre message est trop court")
    .max(5000, "Votre messafe est trop long")
    .required("Votre message est requis"),
})

let afterSubmitMessage = ""

const ContactForm: React.SFC<{}> = () => {
  return (
    <section className="container-fluid px-0" id="contact">
      <div className="row no-gutters">
        <div className="col-lg-6 px-4 px-xl-5 py-5 border-top">
          <h2 className="h4 mb-4">Contactez nous</h2>

          <Formik
            initialValues={{
              name: "",
              mail: "",
              phone: "",
              subject: "",
              message: "",
              address: "",
            }}
            validationSchema={ContactSchema}
            onSubmit={(values, actions) => {
              fetch("/#contact", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "contact", ...values }),
              })
                .then(() => {
                  afterSubmitMessage = "Message envoyé"
                  actions.resetForm()
                })
                .catch(() => {
                  afterSubmitMessage = "Erreur technique, veuillez réessayer"
                })
                .finally(() => actions.setSubmitting(false))
            }}
            render={({
              handleChange,
              values,
              isValid,
              dirty,
              errors,
              touched,
            }: FormikProps<FormValues>) => (
              <Form
                className="needs-validation mb-3"
                method="post"
                action="/#contact"
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <Field type="hidden" name="form-name" />

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="name">Nom:&nbsp;</label>
                      <Field
                        className={
                          "form-control" +
                          (errors.name && touched.name
                            ? " is-invalid"
                            : touched.name
                            ? " is-valid"
                            : "")
                        }
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Nemo Marin"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="mail">Email:&nbsp;</label>
                      <Field
                        className={
                          "form-control" +
                          (errors.mail && touched.mail
                            ? " is-invalid"
                            : touched.mail
                            ? " is-valid"
                            : "")
                        }
                        id="mail"
                        type="email"
                        name="mail"
                        placeholder="nemo@email.fr"
                      />
                      <ErrorMessage
                        name="mail"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="phone">Votre téléphone:&nbsp;</label>
                      <Field
                        id="phone"
                        className={
                          "form-control" +
                          (errors.phone && touched.phone
                            ? " is-invalid"
                            : touched.phone
                            ? " is-valid"
                            : "")
                        }
                        type="input"
                        name="phone"
                        placeholder="0601010202"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="subject">Le sujet:&nbsp;</label>
                      <Field
                        className={
                          "form-control custom-select" +
                          (errors.subject && touched.subject
                            ? " is-invalid"
                            : touched.subject
                            ? " is-valid"
                            : "")
                        }
                        id="subject"
                        type="input"
                        name="subject"
                        placeholder="Message"
                        component="select"
                      >
                        <option value="message">Message</option>
                        <option value="devis">Demande de devis</option>
                        <option value="technical">Support technique</option>
                      </Field>
                      <ErrorMessage
                        name="subject"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="adresse">Votre adresse postale:&nbsp;</label>
                  <Field
                    id="address"
                    className={
                      "form-control" +
                      (errors.address && touched.address
                        ? " is-invalid"
                        : touched.address
                        ? " is-valid"
                        : "")
                    }
                    type="input"
                    name="address"
                    placeholder="1 Rue du bac, 55000 VAL D'ORNAIN."
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="message">Message:&nbsp;</label>
                  <Field
                    className={
                      "form-control" +
                      (errors.message && touched.message
                        ? " is-invalid"
                        : touched.message
                        ? " is-valid"
                        : "")
                    }
                    id="message"
                    type="input"
                    name="message"
                    placeholder="Bonjour"
                    component="textarea"
                  ></Field>
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="row">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!(isValid && dirty)}
                  >
                    Envoyer le message
                  </button>
                </div>
                <div className="row">{afterSubmitMessage}</div>
              </Form>
            )}
          />
        </div>
        <div className="col-lg-6 iframe-full-height-wrap">
          <iframe
            className="iframe-full-height"
            width="600"
            height="250"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10511.631456730478!2d5.070723904547451!3d48.80273707158904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eb77b14772e581%3A0x4c1e966c9dfc95cf!2s4%20Rue%20des%20Pierre%2C%2055000%20Val-d&#39;Ornain!5e0!3m2!1sfr!2sfr!4v1590600646294!5m2!1sfr!2sfr"
          ></iframe>
        </div>
      </div>
    </section>
  )
}
export default ContactForm
