// You can delete this file if you're not using it
import { ApolloProvider } from "@apollo/client"
import "@popperjs/core/dist/umd/popper.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "jquery/dist/jquery.min.js"
import React from "react"
import client from "./src/client"
import "./src/styles/dist/vendor.min.css"
import "./src/styles/scss/theme.scss"

if (typeof window !== "undefined") {
  window.jQuery = window.$ = require("jquery")
  require("bootstrap")
}
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }, { uri, headers, credentials }) => {
  return <ApolloProvider client={client}>{element}</ApolloProvider>
}
