import React from 'react';
import ContactForm from "../ContactForm/ContactForm";


export const Tars = props => {

  return (
    <ContactForm></ContactForm>
  );
};
