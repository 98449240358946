// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-checkout-vendure-ts": () => import("./../../../src/pages/checkout.vendure.ts" /* webpackChunkName: "component---src-pages-checkout-vendure-ts" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-templates-blog-article-detail-tsx": () => import("./../../../src/templates/BlogArticleDetail.tsx" /* webpackChunkName: "component---src-templates-blog-article-detail-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/Categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */),
  "component---src-templates-landing-page-jsx": () => import("./../../../src/templates/LandingPage.jsx" /* webpackChunkName: "component---src-templates-landing-page-jsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/ProductDetail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */)
}

