// src/gatsby-theme-apollo/client.js
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: new HttpLink({
//     uri: "https://vendure-zwf42tfxwa-ew.a.run.app/shop-api",
//     fetch,
//   }),
//   credentials: "include",
// })

const link = createHttpLink({
  credentials: "include",
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})

export default client
