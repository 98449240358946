import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { ShoppingCart } from "../ShoppingCart/ShoppingCart"
import "./Navigation.scss"

export default function Navigation() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      vendure {
        collections {
          items {
            id
            name
          }
        }
      }
    }
  `)
  return (
    <header>
      <div className="navbar navbar-expand-lg navbar-light box-shadow-sm deo">
        <div className="container">
          {/* Brand */}

          <Link
            className="navbar-brand d-none d-lg-block mr-3 flex-shrink-0"
            to="/"
          >
            <img
              width="200"
              src="/img/jeffpiscine-logo-plus-text.png"
              alt="Jeffpiscine"
            />
          </Link>

          {/* Toolbar */}
          <div className="navbar-toolbar d-flex align-items-center order-lg-3">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <a className="navbar-tool d-lg-none" href="#">
              <div className="navbar-tool-icon-box">
                <i className="navbar-tool-icon czi-search"></i>
              </div>
            </a> */}

            <ShoppingCart />
          </div>

          {/* <!-- Collapsible menu --> */}
          <div
            className="collapse navbar-collapse mr-auto order-lg-2"
            id="navbarCollapse"
          >
            <hr className="d-lg-none mt-3 mb-2" />

            <ul className="navbar-nav">
              <li class="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/products/">
                  Nos produits
                </Link>
                <ul class="dropdown-menu">
                  {data.vendure.collections.items.map(collection => (
                    <li>
                      <Link
                        className="dropdown-item py-2 border-bottom"
                        activeClassName="active"
                        to={`/category/${collection.id}`}
                      >
                        <span>{collection.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/category/12">
                  Nos spas
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#contact">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}
