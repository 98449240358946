/**
 * Formats a Vendure price (an integer) into a localised dollar-cents format.
 * @param currency
 * @param value
 */
export function formatPrice(currency: string, value: number): string {
  if (value == 0) {
    return "Tarif sur devis"
  }
  return new Intl.NumberFormat("fr", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  }).format(value / 100)
}
