import { useMutation, useQuery } from "@apollo/client"
import { Link } from "gatsby"
import React, { useState } from "react"
import { formatPrice } from "../../utilities/utilities"
import styles from "./ShoppingCart.module.scss"
import { ADJUST_ITEM_QUANTITY, GET_ACTIVE_ORDER } from "./ShoppingCart.vendure"

export function ShoppingCart() {
  const { data, error, loading } = useQuery(GET_ACTIVE_ORDER)
  const [opened, setOpened] = useState(false)
  const [adjustItemQuantity] = useMutation(ADJUST_ITEM_QUANTITY)
  if (loading) {
    return <div>Chargement...</div>
  }
  if (error) {
    return <div>Erreur! {error.message}</div>
  }
  return (
    <>
      <Link to="/checkout">
        <div className="navbar-tool ml-3">
          <div className="navbar-tool-icon-box bg-secondary">
            <span className="navbar-tool-label">
              {data.activeOrder
                ? data.activeOrder.lines.reduce(
                    (total, line) => total + line.quantity,
                    0
                  )
                : "0"}
            </span>
            <i className="navbar-tool-icon czi-view-list"></i>
          </div>
          <div className="navbar-tool-text">
            <small>Ma liste</small>{" "}
            {data.activeOrder
              ? formatPrice(
                  data.activeOrder.currencyCode,
                  data.activeOrder.total
                )
              : "Vide"}
          </div>
        </div>

        <div
          className={[styles.cartTray, opened && styles.opened]
            .filter(Boolean)
            .join(" ")}
        >
          <div className="delete" onClick={() => setOpened(false)}>
            <i className="czi-close"></i>
          </div>
          <h3 className="title is-3">Ma liste</h3>
          {data.activeOrder ? (
            <>
              <CartContentsList
                order={data.activeOrder}
                adjustQuantity={(id, quantity) =>
                  adjustItemQuantity({
                    variables: { id, quantity },
                  })
                }
              />

              <Link className="button is-primary" to="/checkout">
                Envoyer ma demande
              </Link>
            </>
          ) : (
            <div>Votre liste est vide</div>
          )}
        </div>
      </Link>
    </>
  )
}

export function CartContentsList({
  order,
  adjustQuantity,
}: {
  order: any
  adjustQuantity?: Function
}) {
  return (
    <>
      <aside className="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
        <div className="bg-white rounded-3 shadow-lg p-4 ms-lg-auto">
          <div className="py-2 px-xl-2">
            <div className="widget mb-3">
              <h2 className="widget-title text-center">Ma liste</h2>

              {order.lines.map(line => (
                <CartContentsRow
                  line={line}
                  adjustQuantity={adjustQuantity}
                  key={line.id}
                />
              ))}
            </div>
            <ul className="list-unstyled fs-sm pb-2 border-bottom">
              <li className="d-flex justify-content-between align-items-center">
                <span className="me-2">Total:</span>
                <span className="text-end">
                  {formatPrice(order.currencyCode, order.total)}
                </span>
              </li>
              <li className="d-flex justify-content-between align-items-center">
                <span className="me-2">Remise:</span>
                <span className="text-end">—</span>
              </li>
            </ul>
            <h3 className="fw-normal text-center my-4">
              {formatPrice(order.currencyCode, order.total)}
            </h3>
            <form className="needs-validation" method="post">
              <div className="mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="code"
                  required
                />
                <div className="invalid-feedback">code</div>
              </div>
              <button
                className="btn btn-outline-primary d-block w-100"
                type="submit"
                disabled
              >
                Appliquer une réduction
              </button>
            </form>
          </div>
        </div>
      </aside>
    </>
  )
}

function CartContentsRow({
  line,
  adjustQuantity,
}: {
  line: any
  adjustQuantity?: Function
}) {
  return (
    <>
      <div className="d-flex align-items-center pb-2 border-bottom">
        <a className="d-block flex-shrink-0" href="shop-single-v1.html">
          <img src={`${line.featuredAsset.preview}`} width="70" alt="Product" />
        </a>
        <div className="ps-2 ml-4">
          <h6 className="widget-product-title">
            <a href="shop-single-v1.html">{line.productVariant.name}</a>
          </h6>
          <div className="widget-product-meta">
            <span className="text-accent me-2">
              {formatPrice("EUR", line.unitPriceWithTax)}
            </span>
            <span className="text-muted"> x {line.quantity}</span>
          </div>
        </div>
      </div>
    </>
  )
}
